
/*-------- 4. Slider style ---------*/

.slider-area {
	position: relative;
    overflow: hidden;
}

.slider-height-1 {
    height: 991px;
    @media #{$xx-layout} {
        height: 891px;
    }
    @media #{$xl-layout} {
        height: 780px;
    }
    @media #{$lg-layout} {
        height: 750px;
    }
    @media #{$md-layout} {
        height: 700px;
    }
    @media #{$xs-layout} {
        height: 550px;
    }
}
.slider-height-2 {
    height: 773px;
    @media #{$xl-layout} {
        height: 650px;
    }
    @media #{$lg-layout} {
        height: 600px;
    }
    @media #{$md-layout} {
        height: 450px;
    }
    @media #{$xs-layout} {
        height: auto;
        padding: 50px 0 50px;
    }
}
.slider-height-3 {
    height: 813px;
    @media #{$xx-layout} {
        height: 713px;
    }
    @media #{$xl-layout} {
        height: 650px;
    }
    @media #{$lg-layout} {
        height: 600px;
    }
    @media #{$md-layout} {
        height: 450px;
    }
    @media #{$xs-layout} {
        height: auto;
        padding: 50px 0 50px;
    }
}

.slider-height-4 {
    height: 1030px;
    @media #{$xx-layout} {
        height: 900px;
    }
    @media #{$xl-layout} {
        height: 900px;
    }
    @media #{$lg-layout} {
        height: 800px;
    }
    @media #{$md-layout} {
        height: 650px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
}

.slider-height-5 {
    height: 1090px;
    @media #{$xx-layout} {
        height: 900px;
    }
    @media #{$xl-layout} {
        height: 900px;
    }
    @media #{$lg-layout} {
        height: 800px;
    }
    @media #{$md-layout} {
        height: 650px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
}

.slider-height-6 {
    height: 1090px;
    @media #{$xx-layout} {
        height: 900px;
    }
    @media #{$xl-layout} {
        height: 900px;
    }
    @media #{$lg-layout} {
        height: 800px;
    }
    @media #{$md-layout} {
        height: 650px;
    }
    @media #{$xs-layout} {
        height: 550px;
    }
}

.slider-height-7 {
    height: 991px;
    @media #{$xx-layout} {
        height: 700px;
    }
    @media #{$xl-layout} {
        height: 700px;
    }
    @media #{$lg-layout} {
        height: 650px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 450px;
    }
}

.slider-height-8 {
    height: 1090px;
    @media #{$xx-layout} {
        height: 700px;
    }
    @media #{$xl-layout} {
        height: 700px;
    }
    @media #{$lg-layout} {
        height: 650px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 450px;
    }
}

.slider-height-9 {
    height: 991px;
    @media #{$xx-layout} {
        height: 650px;
    }
    @media #{$xl-layout} {
        height: 650px;
    }
    @media #{$lg-layout} {
        height: 600px;
    }
    @media #{$md-layout} {
        height: 500px;
    }
    @media #{$xs-layout} {
        height: 400px;
    }
}

.slider-height-10 {
    height: 1090px;
    @media #{$xx-layout} {
        height: 750px;
    }
    @media #{$xl-layout} {
        height: 750px;
    }
    @media #{$lg-layout} {
        height: 700px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 480px;
    }
}

.hero-slider-content-1 {
	width: 100%;
    h2 {
        font-size: 59px;
        color: #fff;
        font-family: $playfair;
        margin: 0;
        letter-spacing: -2px;
        font-style: italic;
        @media #{$xl-layout} {
            font-size: 50px;
        }
        @media #{$lg-layout} {
            font-size: 40px;
        }
        @media #{$md-layout} {
            font-size: 40px;
        }
        @media #{$xs-layout} {
            font-size: 32px;
        }
    }
    h1 {
        font-size: 149px;
        color: #fff;
        font-family: $playfair;
        margin: 10px 0 35px;
        line-height: 130px;
        letter-spacing: -5px;
        @media #{$xx-layout} {
            font-size: 130px; 
        }
        @media #{$xl-layout} {
            font-size: 120px;
        }
        @media #{$lg-layout} {
            font-size: 100px;
            line-height: 100px;
        }
        @media #{$md-layout} {
            font-size: 90px;
            line-height: 90px;
        }
        @media #{$xs-layout} {
            font-size: 40px;
            line-height: 45px;
            margin: 10px 0 30px;
            letter-spacing: 0;
        }
    }
}

.btn-style-1 {
    a {
        display: inline-block;
        color: $black;
        line-height: 1;
        font-size: 20px;
        background-color: $white;
        padding: 26px 35px 29px;
        &.btn-1-font-2 {
            font-size: 18px;
            @media #{$xs-layout} {
                font-size: 14px;
            }
        }
        &.btn-1-padding-dec-2 {
            padding: 26px 50px 26px;
            @media #{$lg-layout} {
                padding: 20px 40px 20px;
            }
            @media #{$md-layout} {
                padding: 20px 40px 20px;
            }
            @media #{$xs-layout} {
                padding: 15px 30px 15px;
            }
        }
        &.btn-1-padding-dec-3 {
            padding: 31px 50px 31px;
            @media #{$lg-layout} {
                padding: 26px 40px 26px;
            }
            @media #{$md-layout} {
                padding: 26px 40px 26px;
            }
            @media #{$xs-layout} {
                padding: 15px 30px 15px;
            }
        }
        &.btn-1-padding-dec-4 {
            padding: 21px 40px 26px;
            @media #{$xs-layout} {
                padding: 15px 30px 15px;
            }
        }
        &.black-bg {
            background-color: #181818;
            color: $white;
            &:hover {
                background-color: $theme-color-yellow;
            }
        }
        @media #{$lg-layout} {
            padding: 20px 35px 23px;
        }
        @media #{$md-layout} {
            padding: 20px 35px 23px;
        }
        @media #{$xs-layout} {
            padding: 14px 35px 17px;
            font-size: 17px;
        }
        &:hover {
            color: $white;
            background-color: $black;
        }
    }
}

.hero-slider-active-1 {
    position: relative;
}
.nav-style-1 {
    .slider-icon-1 {
        position: absolute;
        bottom: 8%;
        right: 80px;
        cursor: pointer;
        width: 60px;
        height: 60px;
        line-height: 66px;
        text-align: center;
        z-index: 9;
        border-radius: 50%;
        font-size: 20px;
        color: #1e1e1e;
        background: rgba(255, 255, 255, 0.5);
        @media #{$md-layout} {
            font-size: 15px;
            width: 50px;
            height: 50px;
            line-height: 55px;
        }
        @media #{$xs-layout} {
            right: 20px;
            width: 40px;
            height: 40px;
            line-height: 46px;
            font-size: 16px;
        }
        &.slider-icon-next {
            bottom: 16%;
            @media #{$lg-layout} {
                bottom: 18%;
            }
            @media #{$md-layout} {
                bottom: 17%;
            }
            @media #{$xs-layout} {
                bottom: 18%;
            }
        }
        &:hover {
            background: rgba(255, 255, 255, 1);
        }
    }
    @media #{$lg-layout} {
        &.nav-style-1-bikes {
            .slider-icon-1 {
                right: 30px;
                width: 40px;
                height: 40px;
                line-height: 45px;
                font-size: 17px;
                &.slider-icon-next {
                    bottom: 15%;
                }
            }
        }
    }
    @media #{$md-layout} {
        &.nav-style-1-bikes {
            .slider-icon-1 {
                right: 20px;
                width: 40px;
                height: 40px;
                line-height: 45px;
                font-size: 17px;
                &.slider-icon-next {
                    bottom: 16%;
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.nav-style-1-bikes {
            .slider-icon-1 {
                right: 10px;
            }
        }
    }
    &.jewelry-nav-style {
        .slider-icon-1 {
            &.slider-icon-next {
                bottom: 16%;
                @media #{$xx-layout} {
                    bottom: 20%;
                }
                @media #{$xl-layout} {
                    bottom: 20%;
                }
                @media #{$lg-layout} {
                    bottom: 21%;
                }
                @media #{$md-layout} {
                    bottom: 19%;
                }
                @media #{$xs-layout} {
                    bottom: 22%;
                }
            }
        }
    }
}

.social-info-text {
    position: absolute;
    max-width: 100%;
    bottom: 25%;
    z-index: 2;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    left: 140px;
    @media #{$xx-layout} {
        left: 60px;
        bottom: 12%;
    }
    @media #{$xl-layout} {
        left: 15px;
        bottom: 12%;
    }
    @media #{$lg-layout} {
        left: 30px;
        bottom: 18%;
    }
    @media #{$md-layout} {
        left: 40px;
        bottom: 28%;
        &.hm1 {
            bottom: 17%;
        }
    }
    @media #{$xs-layout} {
        left: 8px;
        bottom: 27%;
        &.hm1 {
            bottom: 16%;
        }
    }
    ul {
        display: flex;
        li {
            margin: 0 24px;
            @media #{$xx-layout} {
                margin: 0 15px;
            }
            @media #{$xl-layout} {
                margin: 0 15px;
            }
            @media #{$lg-layout} {
                margin: 0 15px;
            }
            @media #{$md-layout} {
                margin: 0 15px;
            }
            @media #{$xs-layout} {
                margin: 0 10px;
            }
            a {
                color: $white;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 18px;
                @media #{$xs-layout} {
                    font-size: 15px;
                }
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
}

.single-animation-wrap {
    &.slick-active {
        .slider-animated-1 {
            h2 {
                animation-delay: 1.1s;
                animation-name: fadeInUp;
            }
            p {
                animation-delay: 1.4s;
                animation-name: fadeInUp;
            }
            span {
                animation-delay: 1.1s;
                animation-name: fadeInUp;
            }
            h4 {
                animation-delay: 1.1s;
                animation-name: fadeInUp;
            }
            h3 {
                animation-delay: 1.1s;
                animation-name: fadeInUp;
            }
            h5 {
                animation-delay: 1.4s;
                animation-name: fadeInUp;
            }
            h1 {
                animation-delay: 1.7s;
                animation-name: fadeInUp;
            }
            a {
                animation-delay: 2.0s;
                animation-name: fadeInUp;
            }
            .btn-style-8 {
                animation-delay: 2.0s;
                animation-name: fadeInUp;
            }
            &.hero-single-slider-img-1 {
                img {
                    animation-delay: 1.5s;
                    animation-name: fadeInLeft;
                }
            }
            &.hero-single-slider-img-2 {
                img {
                    animation-delay: 1.6s;
                    animation-name: fadeInRight;
                }
            }
            &.autoparts-slider-offer-1 , &.autoparts-slider-offer-2 {
                img {
                    animation-delay: 1.5s;
                    animation-name: fadeInRight;
                }
            }
            &.hero-single-slider-img-3 {
                img {
                    animation-delay: 1.8s;
                    animation-name: fadeInRight;
                }
            }
            &.hero-single-slider-img-4 , 
            &.hero-single-slider-img-5 , 
            &.hero-single-slider-img-6 {
                img {
                    animation-delay: 1.5s;
                    animation-name: fadeInRight;
                }
            }
            &.hero-single-slider-img-8 {
                img {
                    animation-delay: .5s;
                    animation-name: fadeInRight;
                }
            }
            &.hero-single-img-wrap {
                > img {
                    animation-delay: 1.0s;
                    animation-name: zoomIn;
                }
                .hero-single-slider-img-7 {
                    > img {
                        animation-delay: 1.5s;
                        animation-name: zoomIn;
                    }
                }
                h3 {
                    animation-delay: 1.8s;
                    animation-name: fadeInUp;
                }
            }
            .hero-slider-content-9 {
                h2 {
                    animation-delay: .9s;
                    animation-name: fadeInUp;
                }
            }
            .text-img-1 {
                img {
                    animation-delay: .7s;
                    animation-name: fadeInUp;
                }
            }
            .text-img-2 {
                img {
                    animation-delay: .7s;
                    animation-name: fadeInUp;
                }
            }
            .text-img-3 {
                img {
                    animation-delay: 1.2s;
                    animation-name: fadeInUp;
                }
            }
            .text-img-4 {
                img {
                    animation-delay: .8s;
                    animation-name: fadeInUp;
                }
            }
            .organic-text-img {
                img {
                    animation-delay: 1.6s;
                    animation-name: fadeInUp;
                }
            }
        }
    }
}

.slider-icon-2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #181818;
    background-color: $white;
    z-index: 9;
    cursor: pointer;
    i {
        line-height: 50px;
    }
    @media #{$xs-layout} {
        width: 40px;
        height: 40px;
        line-height: 40px;
        i {
            line-height: 40px;
        }
    }
    &:hover {
        color: $white;
        background-color: #181818;
    }
    &.slider-icon-2-prev {
        left: 0;
    }
    &.slider-icon-2-next {
        right: 0;
    }
}


.footer-two-area {
	height: auto !important;
}

.fullpage-pl-1 {
    padding-left: 365px;
    @media #{$xx-layout} {
        padding-left: 100px;
    }
    @media #{$xl-layout} {
        padding-left: 15px;
    }
    @media #{$lg-layout} {
        padding-left: 30px;
    }
    @media #{$md-layout} {
        padding-left: 25px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
}
.fullpage-content-1 {
    h1 {
        color: #fff;
        font-family: $playfair;
        font-size: 80px;
        letter-spacing: -3px;
        line-height: 1.3em;
        margin: 0 0 51px;
        @media #{$xx-layout} {
            font-size: 70px;
        }
        @media #{$xl-layout} {
            font-size: 70px;
        }
        @media #{$lg-layout} {
            font-size: 55px;
            margin: 0 0 30px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            letter-spacing: 0;
            margin: 0 0 30px;
        }
        @media #{$xs-layout} {
            font-size: 23px;
            margin: 0 0 30px;
            letter-spacing: 0px;
        }
    }
}

.btn-style-3 {
    a {
        display: inline-block;
        line-height: 1;
        color: #181818;
        font-size: 24px;
        background-color: #fff;
        padding: 26px 40px 31px;
        @media #{$lg-layout} {
            font-size: 20px;
            padding: 18px 40px 23px;
        }
        @media #{$md-layout} {
            font-size: 20px;
            padding: 17px 30px 22px;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            padding: 15px 30px 20px;
        }
        &:hover {
            background-color: $theme-color-yellow;
            color: #fff;
        }
    }
}

.fullpage-content-2 {
    h1 {
        color: #fff;
        font-family: $playfair;
        font-size: 100px;
        letter-spacing: -4px;
        line-height: 1.3em;
        margin: 0 0 50px;
        @media #{$xx-layout} {
            font-size: 90px;
        }
        @media #{$xl-layout} {
            font-size: 90px;
        }
        @media #{$lg-layout} {
            font-size: 80px;
            margin: 0 0 35px;
        }
        @media #{$md-layout} {
            font-size: 55px;
            margin: 0 0 30px;
            letter-spacing: 0px;
        }
        @media #{$xs-layout} {
            font-size: 29px;
            margin: 0 0 20px;
            letter-spacing: 0px;
        }
    }
}
.fullpage-content-3 {
    h1 {
        color: #fff;
        font-size: 150px;
        letter-spacing: -5px;
        line-height: 1.4em;
        margin: 0 0 50px;
        @media #{$xx-layout} {
            font-size: 95px;
            line-height: 1.2em;
            margin: 0 0 30px;
        }
        @media #{$xl-layout} {
            font-size: 95px;
            line-height: 1.2em;
            margin: 0 0 30px;
        }
        @media #{$lg-layout} {
            font-size: 60px;
            line-height: 1.2em;
            margin: 0 0 40px;
            letter-spacing: 0;
        }
        @media #{$md-layout} {
            font-size: 50px;
            line-height: 1.2em;
            margin: 0 0 30px;
            letter-spacing: 0px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            letter-spacing: 0px;
            margin: 0 0 25px;
        }
    }
}

#fp-nav {
    z-index: 9;
    @media #{$md-layout} {
        top: 40%;
    }
    @media #{$xs-layout} {
        top: 40%;
    }
    ul {
        li {
            margin: 0px 0 16px;
            @media #{$xs-layout} {
                margin: 0px 0 8px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                span {
                    height: 14px;
                    width: 14px;
                    background: #fff;
                    opacity: .5;
                    margin: 0;
                }
                &.active {
                    span {
                        height: 14px;
                        width: 14px;
                        margin: 0;
                        background: $theme-color-yellow;
                    }
                }
            }
            &:hover {
                a {
                    span {
                        height: 14px;
                        width: 14px;
                        opacity: 1;
                        margin: 0;
                    }
                    &.active {
                        span {
                            height: 14px;
                            width: 14px;
                            opacity: 1;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    &.right {
        right: 68px;
        @media #{$md-layout} {
            right: 40px;
        }
        @media #{$xs-layout} {
            right: 18px;
        }
    }
}

.hero-single-slider-img-1 {
    text-align: center;
    img {
        max-width: 100%;
        display: inline-block;
    }
}

.hero-slider-content-2 {
    margin-right: -100px;
    @media #{$xx-layout} {
        margin-right: 0px;
    }
    @media #{$xl-layout} {
        margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 30px;
    }
    @media #{$sm-layout} {
        margin-right: 0px;
        margin-top: 0px;
    }
    h2 {
        color: rgb(24, 24, 24);
        font-size: 55px;
        line-height: 45px;
        margin: 0;
        @media #{$xx-layout} {
            font-size: 45px;
        }
        @media #{$xl-layout} {
            font-size: 42px;
        }
        @media #{$lg-layout} {
            font-size: 39px;
        }
        @media #{$md-layout} {
            font-size: 28px;
            line-height: 28px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            line-height: 28px;
        }
        @media #{$sm-layout} {
            font-size: 23px;
        }
    }
    p {
        color: rgb(109, 109, 109);
        font-size: 16px;
        line-height: 30px;
        margin: 16px auto 0;
        width: 72%;
        @media #{$xx-layout} {
            width: 100%;
        }
        @media #{$xl-layout} {
            width: 100%;
        }
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
            font-size: 15px;
            line-height: 27px;
            margin: 10px auto 0;
        }
    }
    h1 {
        color: rgb(234, 183, 0);
        font-size: 65px;
        margin: 0 0 25px;
        @media #{$xx-layout} {
            font-size: 55px;
        }
        @media #{$xl-layout} {
            font-size: 52px;
        }
        @media #{$lg-layout} {
            font-size: 52px;
        }
        @media #{$md-layout} {
            font-size: 25px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            margin: 0 0 15px;
        }
    }
}

.btn-style-4  {
    a {
        font-size: 18px;
        display: inline-block;
        background-color: rgb(255, 213, 61);
        color: rgb(24, 24, 24);
        font-weight: bold;
        line-height: 1;
        padding: 27px 50px 32px;
        @media #{$xl-layout} {
            padding: 20px 40px 23px;
        }
        @media #{$lg-layout} {
            padding: 20px 40px 23px;
        }
        @media #{$md-layout} {
            padding: 15px 30px 18px;
            font-size: 16px;
        }
        @media #{$xs-layout} {
            padding: 13px 30px 16px;
            font-size: 16px;
        }
        &.padding-dec-1 {
            padding: 20px 50px 25px;
            @media #{$xx-layout} {
                padding: 15px 30px 20px;
            }
            @media #{$xl-layout} {
                padding: 12px 25px 17px;
            }
            @media #{$lg-layout} {
                padding: 12px 25px 17px;
            }
            @media #{$md-layout} {
                padding: 12px 25px 17px;
            }
            @media #{$xs-layout} {
                padding: 10px 25px 14px;
            }
        }
        &.padding-dec-2 {
            padding: 17px 35px 21px;
            @media #{$xs-layout} {
                padding: 10px 25px 14px;
            }
        }
        &:hover { 
            background-color: #000000;
            color: $white;
        }
    }
}

.hero-slider-content-3 {
    margin-right: -215px;
    @media #{$xx-layout} {
        margin-right: 0px;
    }
    @media #{$xl-layout} {
        margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 20px;
    }
    @media #{$sm-layout} {
        margin-right: 0px;
        margin-top: 0px;
    }
    span {
        letter-spacing: 9.9103px;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        display: inline-block;
        border: 2px solid $white;
        line-height: 1;
        padding: 10px 20px 10px 27px;
    }
    h5 {
        color: $white;
        font-size: 18px;
        letter-spacing: 1.9821px;
        margin: 32px 0 5px;
        @media #{$lg-layout} {
            font-size: 17px;
            letter-spacing: 1px;
        }
        @media #{$xs-layout} {
            font-size: 17px;
            letter-spacing: 1px;
            margin: 22px 0 10px;
        }
    }
    h1 {
        color: rgb(234, 183, 0);
        font-size: 65px;
        font-weight: 600;
        margin: 0 0 28px;
        letter-spacing: 9.9103px;
        font-style: italic;
        @media #{$lg-layout} {
            font-size: 45px;
            letter-spacing: 3.9103px;
        }
        @media #{$md-layout} {
            font-size: 35px;
            letter-spacing: 3.9103px;
        }
        @media #{$xs-layout} {
            font-size: 28px;
            letter-spacing: 3.9103px;
        }
        @media #{$sm-layout} {
            font-size: 28px;
        }
    }
}

.single-hero-slider {
    position: relative;
    .autoparts-slider-offer-1 {
        position: absolute;
        left: 42%;
        top: 33.5%;
        z-index: 9;
        @media #{$xx-layout} {
            left: 49%;
            top: 32%;
        }
        @media #{$xl-layout} {
            left: 45%;
            top: 28.5%;
        }
        @media #{$lg-layout} {
            left: 48%;
            top: 24.5%;
        }
        @media #{$md-layout} {
            left: 30%;
            top: 21.5%;
        }
        @media #{$xs-layout} {
            left: 56%;
            top: 10.5%;
        }
        img {
            width: 247px;
            @media #{$xx-layout} {
                width: 200px;
            }
            @media #{$xl-layout} {
                width: 200px;
            }
            @media #{$lg-layout} {
                width: 170px;
            }
            @media #{$md-layout} {
                width: 170px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
        }
    }
    .autoparts-slider-offer-2 {
        position: absolute;
        right: 9%;
        bottom: 23%;
        z-index: 9;
        @media #{$xs-layout} {
            bottom: 8%;
        }
        img {
            width: 257px;
            @media #{$md-layout} {
                width: 170px;
            }
            @media #{$xs-layout} {
                width: 160px;
            }
        }
    }
}
.hero-single-slider-img-2 {
    text-align: center;
    @media #{$xs-layout} {
        margin-top: 20px;
    }
    &.autoparts-slider-mrg-1 {
        margin: 0 -100px 0 -100px;
        @media #{$xx-layout} {
            margin: 0 0px 0 40px;
        }
        @media #{$xl-layout} {
            margin: 0 0px 0 40px;
        }
        @media #{$lg-layout} {
            margin: 0 0px 0 50px;
        }
        @media #{$md-layout} {
            margin: 0 0px 0 0px;
        }
        @media #{$xs-layout} {
            margin: 20px 0px 0 0px;
        }
    }
    &.autoparts-slider-mrg-2 {
        margin: 0 0px 0 -103px;
        @media #{$xx-layout} {
            margin: 0 0px 0 40px;
        }
        @media #{$xl-layout} {
            margin: 0 0px 0 40px;
        }
        @media #{$lg-layout} {
            margin: 0 0px 0 50px;
        }
        @media #{$md-layout} {
            margin: 0 0px 0 50px;
        }
        @media #{$xs-layout} {
            margin: 20px 0px 0 0px;
            img {
                width: 150px;
            }
        }
    }
    img {
        max-width: 100%;
        display: inline-block;
    }
}
.hero-slider-content-4 {
    margin-left: 40px;
    @media #{$xx-layout} {
        margin-left: 20px;
    }
    @media #{$lg-layout} {
        margin-left: 50px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
    h2 {
        color: #181818;
        font-size: 46px;
        line-height: 63px;
        margin: 0;
        font-weight: bold;
        @media #{$xx-layout} {
            font-size: 37px;
            line-height: 47px;
        }
        @media #{$xl-layout} {
            font-size: 35px;
            line-height: 45px;
        }
        @media #{$lg-layout} {
            font-size: 27px;
            line-height: 40px;
        }
        @media #{$md-layout} {
            font-size: 35px;
            line-height: 40px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            line-height: 40px;
        }
    }
    p {
        color: #181818;
        font-size: 16px;
        line-height: 34px;
        margin: 5px 0 30px;
        width: 100%;
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 30px;
            margin: 5px 0 20px;
        }
    }
}

.btn-style-6 {
    a {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        padding: 23px 50px 30px;
        color: $white;
        background-color: $theme-color-red;
        &:hover {
            background-color: $black;
        }
        @media #{$lg-layout} {
            padding: 16px 35px 23px;
        }
        @media #{$md-layout} {
            padding: 16px 35px 23px;
        }
        @media #{$xs-layout} {
            padding: 14px 30px 21px;
            font-size: 16px;
        }
    }
}

.nav-style-2 {
    > span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #000;
        font-size: 15px;
        background-color: rgba(255,255,255,1);
        z-index: 9;
        cursor: pointer;
        transition: all .3s ease 0s;
        opacity: 0;
        visibility: hidden;
        @media #{$lg-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        @media #{$md-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        @media #{$xs-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        i {
            transform: scale(1);
            transition: all .3s ease 0s;
        }
        &.slider-icon-prev {
            left: 30px;
            @media #{$lg-layout} {
                left: 15px;
            }
            @media #{$md-layout} {
                left: 15px;
            }
            @media #{$xs-layout} {
                left: 15px;
            }
        }
        &.slider-icon-next {
            right: 30px;
            @media #{$lg-layout} {
                right: 15px;
            }
            @media #{$md-layout} {
                right: 15px;
            }
            @media #{$xs-layout} {
                right: 15px;
            }
        }
        &:hover {
            background-color: rgba(255,255,255,0.75);
            i {
                transform: scale(1.5);
            }
        }
    }
    &.nav-style-2-border-radius {
        > span {
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100%;
            background: rgba(0,0,0,0.1);
            color: $white;
            @media #{$xs-layout} {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
            &:hover {
                background: rgba(0,0,0,0.5);
            }
        }
    }
    &:hover {
        > span {
            opacity: 1;
            visibility: visible;
        }
    }
}

.dot-style-2 {
    ul {
        position: absolute;
        left: 50%;
        bottom: 16px;
        transform: translateX(-50%);
        z-index: 9;
        @media #{$xs-layout} {
            bottom: 26px;
        }
        li {
            display: inline-block;
            margin: 0 5px;
            button {
                border: none;
                padding: 0;
                border: 5px solid #5b5b5b;
                background-color: $white;
                width: 13px;
                height: 13px;
                line-height: 13px;
                border-radius: 100%;
                font-size: 0;
                display: inline-block;
            }
            &.slick-active {
                button {
                    border: 5px solid $white;
                    background-color: #5b5b5b;
                }
            }
        }
    }
}

.youtube-video-area {
    position: relative;
    &::before {
        background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9;
    }
    .youtube-bg-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 95;
        h2 {
            color: $white;
            font-size: 79px;
            font-weight: 500;
            margin: 0;
            font-family: $noto;
            @media #{$lg-layout} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 60px;
            }
            @media #{$xs-layout} {
                font-size: 27px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
        p {
            color: $white;
            font-size: 24px;
            margin: 22px 0 58px;
            font-family: $noto;
            @media #{$lg-layout} {
                font-size: 20px;
                margin: 22px 0 48px;
            }
            @media #{$md-layout} {
                font-size: 20px;
                margin: 22px 0 48px;
            }
            @media #{$xs-layout} {
                font-size: 17px;
                margin: 17px 0 30px;
                padding: 0 10px;
            }
        }
    }
}

.compact {
    .buttonBar {
        display: none;
    }
}

.cosmetic-hero-padding-1 {
    margin-bottom: 120px;
    @media #{$lg-layout} {
        margin-bottom: 88px;
    }
    @media #{$md-layout} {
        margin-bottom: 50px;
    }
    @media #{$xs-layout} {
        margin-bottom: 90px;
    }
}

.hero-slider-content-5 {
    margin: 0 0 0 40px;
    position: relative;
    z-index: 99;
    @media #{$xl-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: -80px 0 0 0px;
    }
    h2 {
        font-size: 59px;
        color: #181818;
        margin: 0;
        font-family: $playfair;
        letter-spacing: -0.9911px;
        @media #{$xx-layout} {
            font-size: 53px;
        }
        @media #{$xl-layout} {
            font-size: 53px;
        }
        @media #{$lg-layout} {
            font-size: 44px;
        }
        @media #{$md-layout} {
            font-size: 33px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
        }
        @media #{$sm-layout} {
            font-size: 30px;
        }
    }
    p {
        font-size: 18px;
        color: #6D6D6D;
        line-height: 36px;
        margin: 20px auto 40px;
        width: 60%;
        @media #{$xx-layout} {
            width: 72%;
        }
        @media #{$xl-layout} {
            width: 72%;
        }
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 15px auto 30px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 30px;
            width: 100%;
            margin: 12px auto 25px;
        }
    }
}

.hero-slider-content-6 {
    position: relative;
    z-index: 99;
    @media #{$xs-layout} {
        margin: -105px 0 0;
    }
    span {
        display: block;
        font-size: 24px;
        font-style: italic;
        color: #181818;
        font-family: $playfair;
    }
    h1 {
        display: block;
        font-size: 40px;
        color: #181818;
        font-family: $playfair;
        margin: 12px 0 32px;
        @media #{$xs-layout} {
            margin: 12px 0 25px;
            font-size: 23px;
        }
        @media #{$sm-layout} {
            margin: 12px 0 25px;
            font-size: 40px;
        }
    }
}

.hero-single-slider-img-3 {
	position: absolute;
	right: 22%;
	bottom: -120px;
    z-index: 9;
    @media #{$xx-layout} {
        right: 10%;
    }
    @media #{$xl-layout} {
        right: 5%;
    }
    @media #{$lg-layout} {
        right: 7%;
        bottom: -88px;
    }
    @media #{$md-layout} {
        right: 3%;
        bottom: -50px;
    }
    @media #{$xs-layout} {
        right: auto;
        bottom: -90px;
        left: 50%;
        transform: translateX(-50%);
    }
    img {
        width: 395px;
        @media #{$lg-layout} {
            width: 300px;
        }
        @media #{$md-layout} {
            width: 250px;
        }
        @media #{$xs-layout} {
            width: 110px;
        }
    }
}

.hero-single-slider-img-4 {
	position: absolute;
	right: 37.5%;
	bottom: 0px;
    z-index: 5;
    @media #{$xx-layout} {
        right: 31.5%;
    }
    @media #{$xl-layout} {
        right: 29.5%;
    }
    @media #{$lg-layout} {
        right: 29.5%;
    }
    @media #{$md-layout} {
        right: 28.5%;
    }
    @media #{$xs-layout} {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    img {
        width: 324px;
        @media #{$lg-layout} {
            width: 224px;
        }
        @media #{$md-layout} {
            width: 200px;
        }
        @media #{$xs-layout} {
            width: 150px;
        }
    }
}

.hero-single-slider-img-5 {
	position: absolute;
	right: 16%;
	bottom: -120px;
    z-index: 9;
    @media #{$xx-layout} {
        right: 7%;
    }
    @media #{$xl-layout} {
        right: 5%;
    }
    @media #{$lg-layout} {
        right: 4%;
        bottom: -88px;
    }
    @media #{$md-layout} {
        right: 3%;
        bottom: -50px;
    }
    @media #{$xs-layout} {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: -90px;
    }
    img {
        width: 674px;
        @media #{$xx-layout} {
            width: 530px;
        }
        @media #{$xl-layout} {
            width: 530px;
        }
        @media #{$lg-layout} {
            width: 400px;
        }
        @media #{$md-layout} {
            width: 320px;
        }
        @media #{$xs-layout} {
            width: 150px;
        }
    }
}
.hero-single-slider-img-6 {
	position: absolute;
	right: -19%;
	bottom: 0px;
    z-index: 9;
    @media #{$xs-layout} {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    img {
        width: 1282px;
        @media #{$xx-layout} {
            width: 1120px;
        }
        @media #{$xl-layout} {
            width: 1140px;
        }
        @media #{$lg-layout} {
            width: 880px;
        }
        @media #{$md-layout} {
            width: 810px;
        }
        @media #{$xs-layout} {
            width: 250px;
        }
    }
}

.nav-style-4 {
    > span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        text-align: center;
        color: #000;
        font-size: 15px;
        background: rgba(255, 255, 255, 0.5);
        z-index: 9;
        cursor: pointer;
        border-radius: 50%;
        transition: all .3s ease 0s;
        @media #{$lg-layout} {
            width: 40px;
            height: 40px;
        }
        @media #{$md-layout} {
            width: 40px;
            height: 40px;
        }
        @media #{$xs-layout} {
            width: 40px;
            height: 40px;
        }
        i {
            transform: scale(1);
            transition: all .3s ease 0s;
            line-height: 60px;
            @media #{$lg-layout} {
                line-height: 40px;
            }
            @media #{$md-layout} {
                line-height: 40px;
            }
            @media #{$xs-layout} {
                line-height: 40px;
            }
        }
        &.slider-icon-prev {
            left: 30px;
            @media #{$lg-layout} {
                left: 15px;
            }
            @media #{$md-layout} {
                left: 15px;
            }
            @media #{$xs-layout} {
                left: 15px;
            }
        }
        &.slider-icon-next {
            right: 30px;
            @media #{$lg-layout} {
                right: 15px;
            }
            @media #{$md-layout} {
                right: 15px;
            }
            @media #{$xs-layout} {
                right: 15px;
            }
        }
        &:hover {
            background-color: rgba(255,255,255,0.75);
            i {
                transform: scale(1.5);
            }
        }
    }
}

.hero-slider-content-7 {
    position: relative;
    z-index: 9;
    @media #{$xs-layout} {
        margin-top: -60px;
    }
    h2 {
        font-size: 22px;
        font-weight: bold;
        color: $white;
        margin: 0 0 0;
        font-family: $montserrat;
        text-transform: uppercase;
        letter-spacing: 7.9273px;
        @media #{$xl-layout} {
            font-size: 18px;
            letter-spacing: 5.9273px;
        }
        @media #{$lg-layout} {
            font-size: 18px;
            letter-spacing: 5.9273px;
        }
        @media #{$md-layout} {
            font-size: 18px;
            letter-spacing: 3.927px;
        }
        @media #{$xs-layout} {
            font-size: 14px;
            letter-spacing: 1.927px;
        }
        @media #{$sm-layout} {
            font-size: 18px;
            letter-spacing: 3.927px;
        }
    }
    h1 {
        font-size: 129px;
        font-weight: 800;
        color: $white;
        margin: 20px 0 31px;
        font-family: $montserrat;
        text-transform: uppercase;
        letter-spacing: 7.9273px;
        font-style: italic;
        @media #{$xx-layout} {
            font-size: 90px;
            margin: 10px 0 20px;
        }
        @media #{$xl-layout} {
            font-size: 80px;
            margin: 15px 0 30px;
            line-height: 90px;
        }
        @media #{$lg-layout} {
            font-size: 75px;
            line-height: 60px;
        }
        @media #{$md-layout} {
            font-size: 55px;
            margin: 10px 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 29px;
            margin: 10px 0 20px;
            letter-spacing: 6.927px;
        }
        @media #{$sm-layout} {
            font-size: 36px;
        }
    }
}

.btn-style-8 {
    display: block;
    a {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        color: $white;
        line-height: 1;
        padding: 40px 112px;
        transform: skew(-16deg) !important;
        background-color: $theme-color-red;
        span {
            transform: skew(16deg) !important;
            display: block;
        }
        @media #{$xx-layout} {
            padding: 30px 80px;
        }
        @media #{$xl-layout} {
            padding: 27px 70px;
        }
        @media #{$lg-layout} {
            padding: 23px 50px;
        }
        @media #{$md-layout} {
            padding: 20px 40px;
            font-size: 14px;
        }
        @media #{$xs-layout} {
            padding: 16px 35px;
            font-size: 14px;
        }
        &:hover {
            background-color: rgb(180, 4, 4);
        }
    }
}

.hero-single-slider-img-8 {
	position: absolute;
	right: -68px;
	bottom: 0;
    z-index: 1;
    img {
        max-width: 100%;
    }
    @media #{$xx-layout} {
        right: -200px;
    }
    @media #{$xl-layout} {
        right: -200px;
    }
    @media #{$lg-layout} {
        right: -200px;
    }
    @media #{$md-layout} {
        right: -200px;
    }
    @media #{$xs-layout} {
        right: -327px;
    }
}
.hero-single-img-wrap {
	position: absolute;
	right: 120px;
	bottom: 60px;
    z-index: 2;
    @media #{$lg-layout} {
        right: 50px;
	    bottom: 50px;
    }
    @media #{$md-layout} {
        right: 20px;
	    bottom: 20px;
    }
    @media #{$xs-layout} {
        right: 15px;
	    bottom: 15px;
    }
    > img {
        width: 490px;
        @media #{$xx-layout} {
            width: 350px;
        }
        @media #{$xl-layout} {
            width: 350px;
        }
        @media #{$lg-layout} {
            width: 300px;
        }
        @media #{$md-layout} {
            width: 250px;
        }
        @media #{$xs-layout} {
            width: 170px;
        }
        @media #{$sm-layout} {
            width: 210px;
        }
    }
    .hero-single-slider-img-7 {
        position: absolute; 
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        > img {
            width: 331px; 
            display: inline-block;
            @media #{$xx-layout} {
                width: 231px; 
            }
            @media #{$xl-layout} {
                width: 231px; 
            }
            @media #{$lg-layout} {
                width: 200px; 
            }
            @media #{$md-layout} {
                width: 150px; 
            }
            @media #{$xs-layout} {
                width: 130px; 
            }
            @media #{$sm-layout} {
                width: 150px; 
            }
            &.width-2 {
                width: 289px;
                @media #{$xx-layout} {
                    width: 231px; 
                }
                @media #{$xl-layout} {
                    width: 231px; 
                }
                @media #{$lg-layout} {
                    width: 160px; 
                }
                @media #{$md-layout} {
                    width: 150px; 
                }
                @media #{$xs-layout} {
                    width: 90px; 
                }
                @media #{$sm-layout} {
                    width: 110px; 
                }
            }
        }
    }
    h3 {
        color: $white;
        margin: 0;
        letter-spacing: 3.9636px;
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        bottom: 65px;
        left: 0;
        right: 0;
        text-align: center;
        @media #{$lg-layout} {
            letter-spacing: 1.9636px;
            bottom: 45px;
        }
        @media #{$md-layout} {
            letter-spacing: 0.9636px;
            bottom: 40px;
        }
        @media #{$xs-layout} {
            letter-spacing: 0px;
            bottom: 30px;
            font-size: 12px;
        }
    }
}

.hero-slider-content-8 {
    position: relative;
    z-index: 9;
    @media #{$xs-layout} {
        margin: -70px 0 0;
    }
    h2 {
        font-size: 59px;
        letter-spacing: 3.9636px;
        color: $white;
        margin: 0;
        font-family: $montserrat;
        text-transform: uppercase;
        font-style: italic;
        @media #{$xx-layout} {
            font-size: 45px;
        }
        @media #{$xl-layout} {
            font-size: 45px;
        }
        @media #{$lg-layout} {
            font-size: 26px;
        }
        @media #{$md-layout} {
            font-size: 30px;
        }
        @media #{$xs-layout} {
            font-size: 17px;
            letter-spacing: 0.964px;
        }
        @media #{$sm-layout} {
            font-size: 22px;
            letter-spacing: 1.964px;
        }
    }
    h1 {
        font-size: 59px;
        letter-spacing: 3.9636px;
        color: $white;
        margin: 6px 0 50px;
        font-family: $montserrat;
        text-transform: uppercase;
        font-style: italic;
        font-weight: bold;
        @media #{$xx-layout} {
            font-size: 45px;
            margin: 6px 0 40px;
        }
        @media #{$xl-layout} {
            font-size: 45px;
            margin: 6px 0 40px;
        }
        @media #{$lg-layout} {
            font-size: 35px;
            margin: 6px 0 30px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            margin: 6px 0 30px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            letter-spacing: 0;
            margin: 6px 0 20px;
        }
        @media #{$sm-layout} {
            font-size: 27px;
            letter-spacing: 1px;
        }
    }
}

.kids-slider-shape {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 99;
    @media #{$xs-layout} {
        left: -5px;
    }
}

.hero-slider-content-9 {
    position: relative;
    z-index: 99;
    .text-img-1 {
        img {
            width: 636px;
            display: inline-block;
            @media #{$xx-layout} {
                width: 490px;
            }
            @media #{$xl-layout} {
                width: 490px;
            }
            @media #{$lg-layout} {
                width: 450px;
            }
            @media #{$md-layout} {
                width: 450px;
            }
            @media #{$xs-layout} {
                width: 230px;
            }
        }
    }
    h2 {
        font-size: 36px;
        font-weight: 600;
        color: #181818;
        letter-spacing: -1px;
        margin: 30px 0 32px;
        font-family: $montserrat;
        @media #{$xx-layout} {
            font-size: 30px;
            margin: 25px 0 25px;
        }
        @media #{$xl-layout} {
            font-size: 30px;
            margin: 25px 0 25px;
        }
        @media #{$lg-layout} {
            font-size: 25px;
            margin: 25px 0 25px;
        }
        @media #{$md-layout} {
            font-size: 25px;
            margin: 25px 0 25px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            margin: 15px 0 20px;
        }
    }
}

.kids-slider-mrg-1 {
    margin: -190px 0 0 -70px;
    @media #{$md-layout} {
        margin: 0px 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0px 0 0 0px;
    }
}
.kids-slider-mrg-2 {
    margin: -190px -200px 0 0px;
    @media #{$xx-layout} {
        margin: -190px -20px 0 0px;
    }
    @media #{$xl-layout} {
        margin: -190px 0px 0 0px;
    }
    @media #{$lg-layout} {
        margin: -190px 0px 0 0px;
    }
    @media #{$md-layout} {
        margin: -100px 0px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0px 0px 0 0px;
    }
}
.kids-slider-mrg-3 {
    margin: -190px 0px 0 200px;
    @media #{$xx-layout} {
        margin: -190px 0px 0 50px;
    }
    @media #{$xl-layout} {
        margin: -190px 0px 0 0px;
    }
    @media #{$lg-layout} {
        margin: -190px 0px 0 0px;
    }
    @media #{$md-layout} {
        margin: -100px 0px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0px 0px 0 0px;
    }
}

.btn-style-9 {
    a {
        display: inline-block;
        font-size: 22px;
        font-weight: 600;
        color: $white;
        line-height: 1;
        background-color: $theme-color-pink;
        border-radius: 50px;
        padding: 32px 40px 37px;
        @media #{$xx-layout} {
            padding: 22px 40px 27px;
            font-size: 18px;
        }
        @media #{$xl-layout} {
            padding: 22px 40px 27px;
            font-size: 18px;
        }
        @media #{$lg-layout} {
            padding: 18px 30px 23px;
            font-size: 16px;
        }
        @media #{$md-layout} {
            padding: 18px 30px 23px;
            font-size: 16px;
        }
        @media #{$xs-layout} {
            padding: 15px 30px 20px;
            font-size: 15px;
        }
        &.btn-9-padding-dec-1 {
            padding: 32px 77px 37px;
            @media #{$xx-layout} {
                padding: 22px 50px 27px;
            }
            @media #{$xl-layout} {
                padding: 22px 50px 27px;
            }
            @media #{$lg-layout} {
                padding: 16px 40px 21px;
            }
            @media #{$md-layout} {
                padding: 14px 35px 19px;
            }
            @media #{$xs-layout} {
                padding: 13px 30px 18px;
            }
        }
        &:hover {
            background-color: rgb(255, 58, 45);
        }
    }
}

.hero-slider-content-10 {
    position: relative;
    z-index: 99;
    .text-img-2 {
        img {
            width: 108px;
            display: inline-block;
        }
    }
    .text-img-3 {
        margin: 0 0 54px;
        @media #{$xs-layout} {
            margin: 10px 0 30px;
        }
        img {
            width: 646px;
            display: inline-block;
            @media #{$xx-layout} {
                width: 446px;
            }
            @media #{$xl-layout} {
                width: 446px;
            }
            @media #{$lg-layout} {
                width: 430px;
            }
            @media #{$md-layout} {
                width: 430px;
            }
            @media #{$xs-layout} {
                width: 220px;
            }
        }
    }
    .text-img-4 {
        margin: 0 0 64px;
        @media #{$xx-layout} {
            margin: 0 0 44px;
        }
        @media #{$xl-layout} {
            margin: 0 0 44px;
        }
        @media #{$lg-layout} {
            margin: 0 0 35px;
        }
        @media #{$md-layout} {
            margin: 0 0 35px;
        }
        @media #{$xs-layout} {
            margin: 0 0 30px;
        }
        img {
            width: 589px;
            display: inline-block;
            @media #{$lg-layout} {
                width: 400px;
            }
            @media #{$md-layout} {
                width: 400px;
            }
            @media #{$xs-layout} {
                width: 260px;
            }
        }
    }
}

.mouse-scroll-area {
	position: absolute;
	top: 0% !important;
	left: 0 !important;
	z-index: 9 !important;
	width: 100% !important;
	height: 100% !important;
    .kids-shape-1 {
        position: absolute;
        top: 10% !important;
        left: 80% !important;
        z-index: 9;
        @media #{$md-layout} {
            left: 75% !important;
        }
        @media #{$xs-layout} {
            left: 55% !important;
        }
        img {
            width: 218px;
            @media #{$xx-layout} {
                width: 180px;
            }
            @media #{$xl-layout} {
                width: 180px;
            }
            @media #{$lg-layout} {
                width: 150px;
            }
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
    .kids-shape-2 {
        position: absolute;
        top: 27% !important;
        left: 81% !important;
        z-index: 9;
        @media #{$xx-layout} {
            left: 83% !important;
        }
        @media #{$xl-layout} {
            left: 83% !important;
        }
        @media #{$lg-layout} {
            left: 83% !important;
        }
        @media #{$md-layout} {
            left: 77% !important;
        }
        @media #{$xs-layout} {
            left: 77% !important;
        }
        img {
            width: 289px;
            @media #{$xx-layout} {
                width: 190px;
            }
            @media #{$xl-layout} {
                width: 190px;
            }
            @media #{$lg-layout} {
                width: 160px;
            }
            @media #{$md-layout} {
                width: 160px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
        }
    }
    .kids-shape-3 {
        position: absolute;
        top: 64% !important;
        left: 77% !important;
        z-index: 9;
        @media #{$xx-layout} {
            top: 62% !important;
        }
        @media #{$xl-layout} {
            top: 62% !important;
        }
        @media #{$lg-layout} {
            top: 62% !important;
        }
        @media #{$md-layout} {
            top: 62% !important;
        }
        @media #{$xs-layout} {
            top: 62% !important;
            left: 57% !important;
        }
        img {
            width: 181px;
            @media #{$xx-layout} {
                width: 150px;
            }
            @media #{$xl-layout} {
                width: 150px;
            }
            @media #{$lg-layout} {
                width: 120px;
            }
            @media #{$md-layout} {
                width: 120px;
            }
            @media #{$xs-layout} {
                width: 80px;
            }
        }
    }
    .kids-shape-4 {
        position: absolute;
        top: 62% !important;
        left: 8% !important;
        z-index: 9;
        @media #{$xs-layout} {
            left: 4% !important;
            top: 65% !important;
        }
        img {
            width: 181px;
            @media #{$xx-layout} {
                width: 150px;
            }
            @media #{$xl-layout} {
                width: 150px;
            }
            @media #{$lg-layout} {
                width: 150px;
            }
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 90px;
            }
        }
    }
    .kids-shape-5 {
        position: absolute;
        top: 45% !important;
        left: 0% !important;
        z-index: 9;
        @media #{$md-layout} {
            top: 40% !important;
        }
        @media #{$xs-layout} {
            top: 20% !important;
        }
        img {
            width: 173px;
            @media #{$xx-layout} {
                width: 150px;
            }
            @media #{$xl-layout} {
                width: 150px;
            }
            @media #{$lg-layout} {
                width: 150px;
            }
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
}

.hero-slider-content-11 {
    h4 {
        font-size: 40px;
        color: $white;
        font-family: $playfair;
        font-style: italic;
        margin: 0;
        letter-spacing: -0.991px;
        line-height: 1;
    }
    h1 {
        font-size: 99px;
        color: $white;
        font-family: $playfair;
        font-style: italic;
        margin: 0 0 57px;
        letter-spacing: -2.973px;
        @media #{$xl-layout} {
            margin: 0 0 40px; 
        }
        @media #{$lg-layout} {
            margin: 0 0 40px; 
            font-size: 90px;
        }
        @media #{$md-layout} {
            margin: 0 0 30px; 
            font-size: 85px;
        }
        @media #{$xs-layout} {
            margin: 0 0 30px; 
            font-size: 40px;
            letter-spacing: 0;
        }
        @media #{$sm-layout} {
            font-size: 52px;
        }
    }
}

.hero-slider-content-12 {
    h1 {
        font-size: 79px;
        color: $white;
        font-family: $playfair;
        font-style: italic;
        margin: 0 0 47px;
        letter-spacing: -3px;
        @media #{$xl-layout} {
            font-size: 70px;
            margin: 0 0 40px;
        }
        @media #{$lg-layout} {
            font-size: 70px;
            margin: 0 0 40px;
        }
        @media #{$md-layout} {
            font-size: 55px;
            margin: 0 0 40px;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            margin: 0 0 30px;
            letter-spacing: 0;
        }
    }
}
.hero-slider-content-13 {
    margin: 0 0 0 -40px;
    @media #{$xl-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0 0 0px;
    }
    h1 {
        font-size: 79px;
        color: #343434;
        font-family: $playfair;
        font-style: italic;
        margin: 0 0 25px;
        letter-spacing: -3px;
        line-height: 99px;
        @media #{$xl-layout} {
            font-size: 67px;
            line-height: 85px;
        }
        @media #{$lg-layout} {
            font-size: 67px;
            line-height: 85px;
        }
        @media #{$md-layout} {
            font-size: 65px;
            line-height: 75px;
        }
        @media #{$xs-layout} {
            font-size: 40px;
            line-height: 45px;
            letter-spacing: 0;
        }
    }
}

.btn-style-10 {
    a {
        display: inline-block;
        background-color: $white;
        line-height: 1;
        font-size: 18px;
        color: $black;
        padding: 22px 40px 27px;
        @media #{$xs-layout} {
            font-size: 16px;
            padding: 13px 25px 18px;
        }
        &.bg-black {
            color: $white;
            background-color: $black;
            &:hover {
                color: $black;
                background-color: $white;
            }
        }
        &:hover {
            color: $white;
            background-color: $black;
        }
    }
}


.btn-style-11 {
    a {
        display: inline-block;
        background-color: rgb(24, 24, 24);
        line-height: 1;
        font-size: 18px;
        color: $white;
        padding: 21px 50px 27px;
        @media #{$lg-layout} {
            font-size: 16px;
            padding: 16px 35px 22px;
        }
        @media #{$md-layout} {
            font-size: 16px;
            padding: 16px 35px 22px;
        }
        @media #{$xs-layout} {
            font-size: 16px;
            padding: 13px 25px 18px;
        }
        &:hover {
            color: #181818;
            background-color: #F3FCEB;
        }
    }
}

.nav-style-5 {
    .slider-icon-1 {
        position: absolute;
        bottom: 7%;
        cursor: pointer;
        width: 60px;
        height: 60px;
        line-height: 66px;
        text-align: center;
        z-index: 9;
        border-radius: 50%;
        font-size: 20px;
        color: #1e1e1e;
        background: rgba(255, 255, 255, 0.5);
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        @media #{$md-layout} {
            width: 50px;
            height: 50px;
            line-height: 56px;
            font-size: 16px;
        }
        @media #{$xs-layout} {
            width: 40px;
            height: 40px;
            line-height: 46px;
            font-size: 16px;
        }
        &.slider-icon-prev {
            margin-left: -40px;
            @media #{$md-layout} {
                margin-left: -35px;
            }
            @media #{$xs-layout} {
                margin-left: -25px;
            }
        }
        &.slider-icon-next {
            margin-left: 40px;
            @media #{$md-layout} {
                margin-left: 35px;
            }
            @media #{$xs-layout} {
                margin-left: 25px;
            }
        }
        &:hover {
            background: rgba(255, 255, 255, 1);
        }
    }
}

.slider-content-14-position-1 {
	position: absolute;
	right: 14%;
	top: 50%;
	transform: translateY(-50%);
    @media #{$xs-layout} {
        right: 0;
        left: 0;
        text-align: center;
    }
}
.slider-content-14-position-2 {
	position: absolute;
	right: 26%;
    bottom: 13%;
    @media #{$xl-layout} {
        right: 10%;
        bottom: 10%;
    }
    @media #{$lg-layout} {
        right: 8%;
        bottom: 10%;
    }
    @media #{$md-layout} {
        right: 8%;
        bottom: 10%;
    }
    @media #{$xs-layout} {
        right: 0;
        left: 0;
        text-align: center;
        bottom: auto;
        top: 50%;
	    transform: translateY(-50%);
    }
}
.slider-content-14-position-3 {
	position: absolute;
    left: 28%;
    top: 37%;
    @media #{$xs-layout} {
        right: 0;
        left: 0;
        text-align: center;
        top: 50%;
	    transform: translateY(-50%);
    }
}

.hero-slider-content-14 {
    h1 {
        font-size: 44px;
        line-height: 59px;
        color: #181818;
        letter-spacing: -1px;
        margin: 0 0 31px;
        font-family: $playfair;
        @media #{$lg-layout} {
            font-size: 40px;
            line-height: 57px;
            margin: 0 0 20px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            line-height: 57px;
            margin: 0 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 26px;
            line-height: 35px;
            letter-spacing: 0px;
            margin: 0 0 23px;
        }
    }
}

.hero-slider-content-15 {
    width: 100%;
    h1 {
        color: $white;
        font-size: 79px;
        font-family: $noto;
        font-weight: 500;
        margin: 0;
        @media #{$xx-layout} {
            font-size: 60px;
        }
        @media #{$xl-layout} {
            font-size: 60px;
        }
        @media #{$lg-layout} {
            font-size: 48px;
        }
        @media #{$md-layout} {
            font-size: 50px;
            padding: 0 10px;
        }
        @media #{$xs-layout} {
            font-size: 28px;
            padding: 0 10px;
        }
        @media #{$sm-layout} {
            font-size: 37px;
        }
        &.slider-content-15-mb-1 {
            margin: 0 0 60px;
            @media #{$md-layout} {
                margin: 0 0 50px;
            }
            @media #{$xs-layout} {
                margin: 0 0 30px;
            }
        }
    }
    p {
        font-size: 24px;
        color: $white;
        margin: 19px 0 60px;
        @media #{$xs-layout} {
            font-size: 19px;
            margin: 10px 0 35px;
        }
    }
}
.hero-slider-content-16 {
    position: relative;
    z-index: 99;
    h3 {
        font-size: 46px;
        font-family: $satisfy;
        color: #181818;
        margin: 0;
    }
    .organic-text-img {
        margin: 20px 0 53px;
        @media #{$xx-layout} {
            margin: 20px 0 33px;
        }
        @media #{$xl-layout} {
            margin: 20px 0 33px;
        }
        @media #{$lg-layout} {
            margin: 20px 0 33px;
        }
        @media #{$md-layout} {
            margin: 20px 0 33px;
        }
        @media #{$xs-layout} {
            margin: 15px 0 25px;
        }
        img {
            display: inline-block;
            width: 545px;
            @media #{$xx-layout} {
                width: 430px;
            }
            @media #{$xl-layout} {
                width: 400px;
            }
            @media #{$lg-layout} {
                width: 345px;
            }
            @media #{$md-layout} {
                width: 345px;
            }
            @media #{$xs-layout} {
                width: 240px;
            }
        }
    }
}

.organic-slider-mrg {
    margin-left: -140px;
    @media #{$xx-layout} {
        margin-left: 0px;
    }
    @media #{$xl-layout} {
        margin-left: 0px;
    }
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
}

.organic-shape-1 {
	position: absolute;
	top: 5% !important;
    left: 41.5% !important;
    @media #{$xx-layout} {
        left: 48.5% !important;
    }
    @media #{$xl-layout} {
        left: 48.5% !important;
    }
    @media #{$lg-layout} {
        left: 48.5% !important;
    }
    @media #{$md-layout} {
        left: 48.5% !important;
    }
    @media #{$xs-layout} {
        left: 48.5% !important;
    }
    img {
        width: 990px;
        @media #{$xx-layout} {
            width: 620px;
        }
        @media #{$xl-layout} {
            width: 620px;
        }
        @media #{$lg-layout} {
            width: 480px;
        }
        @media #{$md-layout} {
            width: 380px;
        }
        @media #{$xs-layout} {
            width: 200px;
        }
        @media #{$sm-layout} {
            width: 250px;
        }
    }
}
.organic-shape-2 {
	position: absolute;
	left: 72% !important;
	top: 65% !important;
    @media #{$lg-layout} {
        top: 60% !important;
    }
    @media #{$md-layout} {
        top: 60% !important;
    }
    @media #{$xs-layout} {
        top: 60% !important;
    }
    img {
        width: 540px;
        @media #{$xx-layout} {
            width: 350px;
        }
        @media #{$xl-layout} {
            width: 350px;
        }
        @media #{$lg-layout} {
            width: 320px;
        }
        @media #{$md-layout} {
            width: 300px;
        }
        @media #{$xs-layout} {
            width: 150px;
        }
        @media #{$sm-layout} {
            width: 250px;
        }
    }
}
.organic-shape-3 {
	position: absolute;
    left: 44% !important;
    top: 80% !important;
    @media #{$xx-layout} {
        top: 69% !important;
    }
    @media #{$xl-layout} {
        top: 69% !important;
    }
    @media #{$lg-layout} {
        top: 69% !important;
    }
    @media #{$md-layout} {
        top: 69% !important;
    }
    @media #{$xs-layout} {
        top: 69% !important;
    }
    img {
        width: 243px;
        @media #{$lg-layout} {
            width: 200px;
        }
        @media #{$md-layout} {
            width: 200px;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
        @media #{$sm-layout} {
            width: 200px;
        }
    }
}

.organic-shape-4 {
	position: absolute;
    left: -5% !important;
    top: 52% !important;
    z-index: 5;
    img {
        width: 431px; 
        @media #{$xx-layout} {
            width: 331px;
        }
        @media #{$xl-layout} {
            width: 331px;
        }
        @media #{$lg-layout} {
            width: 231px;
        }
        @media #{$md-layout} {
            width: 231px;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
        @media #{$sm-layout} {
            width: 200px;
        }
    } 
}

.organic-shape-5 {
	position: absolute;
    left: -5.2% !important;
    top: 23% !important;
    z-index: 5;
    img {
        width: 331px;
        @media #{$xx-layout} {
            width: 231px;
        }
        @media #{$xl-layout} {
            width: 231px;
        }
        @media #{$lg-layout} {
            width: 220px;
        }
        @media #{$md-layout} {
            width: 220px;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
        @media #{$sm-layout} {
            width: 200px;
        }
    }
}

.dot-style-3 {
    ul {
        position: absolute;
        bottom: 2.3%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        li {
            display: inline-block;
            margin: 0 8px;
            button {
                border: none;
                padding: 0;
                background-color: transparent;
                box-shadow: inset 0 0 0 2px #ffffff;
                font-size: 0px;
                width: 16px;
                height: 16px;
                border-radius: 100%;
                
            }
            &.slick-active {
                button {
                    background-color: $white;
                }
            }
        }
    }
}

.fashion-1-slider-bg-color-1 {
    background-color: #C1BAB4;
}
.fashion-1-slider-bg-color-2 {
    background-color: #1C1C1C;
}
.fashion-2-slider-bg-color-2 {
    background-color: #CAC3BB;
}
.fashion-2-slider-bg-color-3 {
    background-color: #776E65;
}
.fashion-2-slider-bg-color-4 {
    background-color: #C3CED4;
}
.fashion-2-slider-bg-color-5 {
    background-color: #574E42;
}
.electronic-slider-bg-color-1 {
    background-color: #FAFAFA;
}
.electronic-slider-bg-color-2 {
    background-color: #232E44;
}

.auto-parts-slider-bg-color-1 {
    background-color: #EDEBEC;
}
.leather-slider-bg-color {
    background-color: #7A7570;
}

.cosmetic-slider-bg-color {
    background-color: #FFE0DD;
}

.organic-slider-bg-color {
    background-color: #F0FBF7;
}
.motorbikes-slider-bg-color {
    background-color: #8E9397;
}
.kids-slider-bg-color {
    background-color: #ECEEEB;
}

.plants-slider-bg-color-1 {
    background-color: #22282D;
}
.plants-slider-bg-color-2 {
    background-color: #B3A18B;
}
.plants-slider-bg-color-3 {
    background-color: #F5F6F8;
}
.furniture-slider-bg-color-1 {
    background-color: #E7E7E9;
}
.furniture-slider-bg-color-2 {
    background-color: #D6D5D0;
}
.furniture-slider-bg-color-3 {
    background-color: #F2E5DD;
}

.jewelry-slider-bg-color-1 {
    background-color: #04231E;
}
.jewelry-slider-bg-color-2 {
    background-color: #E8B3BD;
}
.jewelry-slider-bg-color-3 {
    background-color: #A4886E;
}

@media #{$md-layout} {
    .youtube-video {
        padding-bottom: 0 !important;
    }
}



